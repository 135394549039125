import React from 'react';
import logo from './logo.svg';
import './App.css';
import PageRoutes from './core/routes/Routes';

function App() {
  return (
    <>
      <PageRoutes/>
    </>
  );
}

export default App;
