import { Stack, Pivot, PivotItem } from "@fluentui/react";

import styles from "./AnalysisPanel.module.css";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { Button } from "@fluentui/react-components";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: any;
    citationHeight: string;
    answer: ChatAppResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {

    const isDisabledCitationTab: boolean = !activeCitation;

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Tham khảo"
                headerButtonProps={undefined}
            >
                <div style={{marginTop: '20px', position: 'relative'}}>
                    <div style={{position: 'absolute', top: '-55px', right: '5px'}}>
                        <Button size="medium" icon={<span>X</span>} onClick={() => onActiveTabChanged(AnalysisPanelTabs.CitationTab)} />
                    </div>
                    <h1 style={{fontWeight: 500, fontSize: '18px'}}>{activeCitation?.title}</h1>
                    <div className={styles.citationContent} dangerouslySetInnerHTML={{__html: activeCitation?.content}}></div>
                </div>
            </PivotItem>
        </Pivot>
    );
};
