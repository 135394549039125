import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HomeRoutes from './HomeRoutes';
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Layout from '../../components/layouts/Layout';
import { initializeIcons } from '@fluentui/react';
import Chat from '../../pages/chat/Chat';

const PageRoutes = () => {
    // var layout;
    // if (useLogin) {
    //     var msalInstance = new PublicClientApplication(msalConfig);

    //     // Default to using the first account if no account is active on page load
    //     if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    //         // Account selection logic is app dependent. Adjust as needed for different use cases.
    //         msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    //     }

    //     // Listen for sign-in event and set active account
    //     msalInstance.addEventCallback(event => {
    //         if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    //             const account = event.payload as AccountInfo;
    //             msalInstance.setActiveAccount(account);
    //         }
    //     });

    //     layout = (
    //         <MsalProvider instance={msalInstance}>
    //             <Layout />
    //         </MsalProvider>
    //     );
    // } else {
    //     layout = <Layout />;
    // }

    initializeIcons();

  return (
    <>
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route
                index
                element={
                    <Chat/>
                }
                />
            </Route>
        </Routes>
    </>
  )
}

export default PageRoutes