import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: any) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    // const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer?.choices?.length > 0 ? answer?.choices[0]?.delta?.content : '';
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent || '', isStreaming, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const referencesParse = answer?.choices?.length > 0 
        ? answer?.choices[0]?.references?.content 
            ? JSON.parse(answer?.choices[0]?.references?.content)?.citations : []
        : [];

    const getNumberOfDoc = (items: any) => {
        const numbers = items?.map((item: any) => {
            const match = item.match(/\d+/);
            return match ? parseInt(match[0]) : null; 
        });

        return numbers;
    };

    const groupItemByPath = (items: any) => {
        const groupedItems = items.reduce((item: any, currentItem: any) => {
            const { path, ...rest } = currentItem;
            if (!item[path]) {
                item[path] = [{ ...rest }];
            } else {
                item[path].push({ ...rest });
            }
            return item;
        }, {});
    };

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={true}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={true}
                        /> */}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {/* {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )} */}

            {referencesParse.length > 0 && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }} style={{marginTop: "10px"}}>
                        <span className={styles.citationLearnMore}>Tham khảo:</span>
                        {referencesParse?.filter((itemFilter: any, indexFilter: number) => (getNumberOfDoc(parsedAnswer?.citations).includes(indexFilter + 1) && itemFilter?.filepath))?.map((item: any, index: number) => {
                            return (
                                <a key={index} className={styles.citation} title={item?.filepath} onClick={() => onCitationClicked({...item, itemIndex: index})}>
                                    {`${index + 1}. ${item?.filepath} - part ${index + 1}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {/* {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )} */}
        </Stack>
    );
};
